import React from 'react';

import './footer.scss';

const currentTime = new Date();

const time = currentTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
});

function Footer() {
    return (
        <div className="footer-container">
            <div className='footer'>
                <div className="left-nav">
                    <a href="https://github.com/maryamtb" target='_blank' rel="noreferrer">GitHub</a>
                    <a href="https://www.linkedin.com/in/maryam-tb" target='_blank' rel="noreferrer">LinkedIn</a>
                </div>
                <div className="right-nav">
                    <p>{time}</p>
                </div>
            </div>
        </div>

    );
}

export default Footer;