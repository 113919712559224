import React, { useLayoutEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import gsap from 'gsap';

import "./header.scss";

function Header() {

    const status = useRef()

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            const sp = self.selector('span')
            let hwTl = gsap.timeline();
            hwTl.from(sp, {
                opacity: 0, y: 5, stagger: 1, delay: 18
            })
            hwTl.to(sp, {
                opacity: 1, y: -5
            });

        }, status);
        return () => ctx.revert();
    }, []);

    return (
        <div className="header-container">
            <div className="header">
                <div className="left-nav">
                    <Link
                        to="/" ><span className="maryam">Maryam TB</span><span className="mimo">, but my friends call me mimo</span></Link>
                </div>
                <div className="middle-nav" >
                    <NavLink to="/contact" ref={status}>
                        <span>Open <span></span> for <span></span> clients <span></span> and <span></span> collabs.</span>
                    </NavLink>
                </div>
                <div className="right-nav">
                    <NavLink to="/about">About</NavLink>
                    <NavLink to="/projects">Projects</NavLink>
                    <NavLink to="/lab">Lab</NavLink>
                    <NavLink to="/contact">Contact</NavLink>
                </div>
            </div>
        </div>
    );
}

export default Header;