import React, { useState, useEffect } from 'react';
import sanityClient from "../../client.js";

import arrow from "../../assets/images/diagonal-arrow.png";

import "./lab.scss";

function Lab() {

    const [labData, setlabData] = useState([]);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "labs"] | order(date desc){
                name,
                slug,
                date,
                description,
                url,
                technology
                }`
            )
            .then((labData) => setlabData(labData))
            .catch(console.error);
    }, []);

    if (labData.length === 0) return <div className="loading">Loading...</div>;

    return (
        <div>
            <ul className="lab">
                {labData &&
                    labData.map((lab, idx) => (
                        <ul className='lab-list' key={idx}>
                            <li className="title">{lab.name}</li>
                            <li className="description">{lab.description}</li>
                            <li className="technology">{lab.technology}</li>
                            {lab.url ?
                                <div className='visit'>
                                    <a
                                        href={lab.url}
                                        target='_blank'
                                        rel="noreferrer">Visit
                                        <img src={arrow} className='arrow' width={8} height={8} />
                                    </a>
                                </div> : " "}
                        </ul>
                    ))}
            </ul>
        </div>
    );
}

export default Lab;