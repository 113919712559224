import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import './contact.scss';

function Contact() {

    const form = useRef();


    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_ID)
            .then((result) => {
                console.log(result.text);
                alert("Your mail was sent!")
            }, (error) => {
                console.log(error.text);
                alert('Something went wrong... ' + JSON.stringify(error));
            });
    };

    return (
        <div className="contact">
            <p className='title'>Say hello</p>
            <form onSubmit={handleSubmit} className='contact-form' ref={form}>
                <input placeholder="Your full name" type="text" name="user_name" required />
                <input placeholder="Your email" type="email" name="user_email" required />
                <textarea placeholder="Your message: \n Hi Maryam, I want to build a web app would love to collaborate with you..." name="message" required />
                <button className="submit" type="submit" value="Submit">submit</button>
            </form>
        </div>
    );

}

export default Contact;