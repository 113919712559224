import React, { useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './about.scss';
import arrow from "../../assets/images/diagonal-arrow.png"
import cbcr from "../../assets/images/cbcr.png";
import clarity from "../../assets/images/clarity.png";
import clarityGlasses from "../../assets/images/clarityGlasses.png";
import swave from "../../assets/images/swave.png";
import windows from "../../assets/images/windows.png";
import ws from "../../assets/images/ws.png";
import wswap from "../../assets/images/wswap.png";
gsap.registerPlugin(ScrollTrigger);

function About() {
    const stack = useRef();
    const content = useRef();

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            let tl = gsap.timeline();
            const allthings = self.selector(".content > *")

            tl.from(allthings, {
                opacity: 0, y: 5, stagger: 2, delay: 1
            })
            tl.to(allthings, {
                opacity: 1, y: -5
            })

        }, content);
        return () => ctx.revert();
    }, []);

    return (
        <div className='about'>

            <div className='stack' ref={stack}>
                <p>Java, PHP, Python, Javascript</p>
                <p>React, Redux, Vue.js, Vuex, Next.js, React Native, Expo</p>
                <p>Node.js, Headless CMS, MongoDB, MySQL, GCP, Firebase</p>
                <p>CSS, Node-sass, Tailwind CSS</p>
                <p>GSAP, Three.js</p>
                <br />
                <p>I'm trilingual in English, French, and Arabic.</p>
            </div>

            <div className='prototype-images'>
                <img src={cbcr} className='cbcr' width={200} />
                <img src={clarity} className='clarity' width={300} />
                <img src={swave} className='swave' width={300} />
                <img src={windows} className='windows' width={350} />
                <img src={ws} className='ws' width={350} />
                <img src={wswap} className='wswap' width={250} />
                <img src={clarityGlasses} className='clarityGlasses' width={300} />
            </div>

            <div className='content' ref={content}>

                <div className="experience">
                    <p className='experience-title' id="li">Experience</p>
                    <ul>
                        <li id='li'><span style={{ fontWeight: 600 }}>Creative Technologist at Twitter</span>, Dublin, Ireland, October 2022 - June 2023.</li>
                        <li id='li'><span style={{ fontWeight: 600 }}>Contract Full Stack Developer at Zero Studios</span>, Casablanca Remote, April - August 2022.</li>
                        <li id='li'><span style={{ fontWeight: 600 }}>Web Application Engineer at Serif</span>, Casablanca Remote, March 2021 - March 2022.</li>
                        <li id='li'><span style={{ fontWeight: 600 }}>Freelance Full Stack Developer at Tappy</span>, Stockholm Remote, October 2022 - Jan 2021.</li>
                        <li id='li'><span style={{ fontWeight: 600 }}>Freelance Principal Developer for WindowSwap</span>, Casablanca Remote, May 2020.</li>
                        <li id='li'><span style={{ fontWeight: 600 }}>Freelance Frontend Developer at CC</span>, Casablanca Remote, Summer 2020.</li>
                        <li id='li'><span style={{ fontWeight: 600 }}>Web Application Engineer at Mozilla Builders Incubator</span>, Casablanca Remote, April 2020.</li>
                        <li id='li'><span style={{ fontWeight: 600 }}>Web Admin & Graphic Designer Intern at News Decoder</span>, Paris, France, Summer 2018.</li>
                        <li id='li'><span style={{ fontWeight: 600 }}>Information Technology Intern at Roche</span>, Casablanca, Morocco, Summer 2017.</li>
                    </ul>

                </div>

                <div className="education">
                    <p id='li'>Education</p>
                    <ul>
                        <li id='li'>Master of Arts in Digital Management, Hyper Island, Stockholm, Sweden, 2022.</li>
                        <li id='li'>Bachelor of Science in Management Information Systems, The American University of Paris, Paris, France, 2019.</li>
                        <li id='li'>Computer Science Major, The University of Miami, Florida, USA, 2016. </li>
                    </ul>
                </div>

                <div className="prototypes">
                    <p className='prototypes-title' id="li">Prototypes</p>
                    <ul>
                        <li id='li'>Archo: An all-in-one architecture tool for the 21st century.</li>
                        <li id='li'>Clarity: A bi-directional learning platform combined with interactive, smart headphones for autistic individuals to create a custom and adaptive auditory world.</li>
                        <li id='li'>Swave: A health-tech solution that serves as a translation tool for brain wave activity. The product itself is both a headband and an app. Swave serves as a way to listen to your brain with curiosity and intrigue. A two-way solution that begins with a question mark.</li>
                        <li id='li'>CBCR: A cigarette-butt collecting robot created with Arduino & C++.</li>
                    </ul>
                </div>

                <div className="papers">
                    <p className='papers-title' id='li'>Papers</p>
                    <ol>
                        <li id='li'>Design for Business Efficiency. Optimizing business operations for the architecture industry through design thinking and innovation, 2022.</li>
                        <li id='li'>Machine-Learning Exploration of Bias in Job Descriptions. Comparing sentiment analysis outputs between IBM Watson, Google, and Microsoft Azure NLP APIs with a diverse set of humans, 2021.</li>
                        <li id='li'>How might we reinvigorate `big tech company` customer trust resilience while ensuring an ethical and healthy future for data privacy of voice technology?, 2021.</li>
                        <li id='li'>Gen Z calling the shots: To what extent is the creator economy setting the standard of today’s systematized norms?, 2021.</li>
                    </ol>
                </div>

                <div className="volunteering">
                    <p id='li'>Volunteering</p>
                    <ul>
                        <li id='li'>Web Developer Volunteer at The Probiotics House in Auroville, India, Sustainability, 2018</li>
                        <li id='li'>Web Developer Volunteer at Sahodaran in Auroville, India, Human Rights, 2018</li>
                        <li id='li'>Construction Volunteer with Amis Des Écoles in Oukaimden, Morocco, Education, 2013</li>
                    </ul>
                </div>

                <div className="press">
                    <p id='li'>Press</p>
                    <ul>
                        <li id='li'>Change Your Perspective By Gazing Out Of A Stranger’s Window Online, Forbes.<span> </span>
                            <span className='visit'>
                                <a
                                    href="https://www.forbes.com/sites/lesliewu/2020/07/27/change-your-perspective-by-gazing-out-of-a-strangers-window-online/?sh=264de464d9c4"
                                    target='_blank'
                                    rel="noreferrer">
                                    <span>Visit</span>
                                    <img src={arrow} className='arrow' width={8} height={8} alt="north-east-arrow" />
                                </a>
                            </span>
                        </li>
                        <li id='li'>This website fills the wanderlust void by letting you look out a stranger's window, DesignBoom.<span> </span>
                            <span className='visit'>
                                <a
                                    href="https://www.designboom.com/technology/windowswap-strangers-window-07-16-2020"
                                    target='_blank'
                                    rel="noreferrer">
                                    <span>Visit</span>
                                    <img src={arrow} className='arrow' width={8} height={8} alt="north-east-arrow" />
                                </a>
                            </span>
                        </li>
                        <li id='li'>'Travel without moving': how people are seeing the world through strangers' windows, The Guardian.<span> </span>
                            <span className='visit'>
                                <a
                                    href="https://www.theguardian.com/artanddesign/2020/jul/15/covid-19-travel-windowswap-photos"
                                    target='_blank'
                                    rel="noreferrer">
                                    <span>Visit</span>
                                    <img src={arrow} className='arrow' width={8} height={8} alt="north-east-arrow" />
                                </a>
                            </span>
                        </li>
                        <li id='li'>'Window Swap' Lets You Watch A Stranger's View And It's Incredibly Calming, Huffpost.<span> </span>
                            <span className='visit'>
                                <span>
                                    <a
                                        href="https://www.huffpost.com/archive/in/entry/window-swap-lets-you-watch-a-strangers-view-and-its-incredibly-calming_in_5f1051f9c5b6cec246bf0324"
                                        target='_blank'
                                        rel="noreferrer">Visit
                                        <img src={arrow} className='arrow' width={8} height={8} alt="north-east-arrow" />
                                    </a>
                                </span>

                            </span>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    );
}

export default About;