import React, { useState, useEffect } from 'react';
import sanityClient from "../../client.js";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper';

import blur from "../../assets/images/blur.png"
import arrow from "../../assets/images/diagonal-arrow.png"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import "./projects.scss";

function Projects() {

    const [projectData, setprojectData] = useState([]);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "projects"] | order(date desc){
                name,
                slug,
                date,
                description,
                association,
                url,
                "bannerImage": bannerImage.asset->url
                }`
            )
            .then((projectData) => setprojectData(projectData))
            .catch(console.error);
    }, []);

    if (projectData.length === 0) return <div className="loading">Loading...</div>;

    return (
        <div className="projects-container">
            <Swiper
                modules={[Mousewheel, Pagination]}
                initialSlide={0}
                mousewheel={true}
                spaceBetween={5}
                slidesPerView={1}
                pagination={{
                    el: ".snp-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + (index + 1) + "</span>";
                    },
                }}
                scrollbar={{ draggable: true }}
                loop={true}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 50
                    }
                }
                }
            >
                {projectData &&
                    projectData.map((project, idx) => (
                        <SwiperSlide key={idx}>
                            <div className="projects">
                                <ul>
                                    <li>
                                        <div className="title-box">
                                            <div className="table">
                                                <div className="row">
                                                    <div className="column">
                                                        <p><span className="title">{project.name}</span> - {project.association}</p>
                                                        <p className="description">{project.description}</p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="column">
                                                        {project.url ?
                                                            <p style={{ paddingTop: 43 }}>
                                                                <a
                                                                    href={project.url}
                                                                    className='visit'
                                                                    target='_blank'
                                                                    rel="noreferrer">Visit
                                                                    <img src={arrow} className='arrow' width={8} height={8} alt="diagonal-arrow" />
                                                                </a>
                                                            </p>
                                                            :
                                                            <p
                                                                style={{ paddingTop: 35 }}
                                                                className='visit'>
                                                                Coming Soon
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='image-container'>
                                                <LazyLoadImage
                                                    effect="blur"
                                                    placeholdersrc={blur}
                                                    id="banner-image" width={"100%"}
                                                    height={"auto"}
                                                    src={project.bannerImage}
                                                    alt="banner-image"
                                                />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </SwiperSlide>
                    ))}
                <span className="snp-pagination"></span>
            </Swiper>
        </div>
    );
}

export default Projects;