import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';

import './index.scss';

const Index = () => {
    const hw = useRef();
    const main = useRef();

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            const sp = self.selector('span')
            let hwTl = gsap.timeline();
            hwTl.from(sp, {
                opacity: 0, y: 5, stagger: 0.1, delay: 0.25
            })
            hwTl.to(sp, {
                opacity: 1, y: -5
            });

        }, hw);
        return () => ctx.revert();
    }, []);

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            const sp = self.selector('span')
            let mainTl = gsap.timeline();
            sp.forEach(s => {
                mainTl.from(s, {
                    y: 5, opacity: 0, stagger: 0.1, delay: 0.35
                })
                mainTl.to(s, {
                    opacity: 1, y: -5, delay: -0.5
                });
            })

        }, main);
        return () => ctx.revert();
    }, []);

    return (
        <div className='index'>
            <div className='main' ref={main}>
                <p><span>I'm Maryam Touimi Benjelloun,</span> <span> full stack engineer,</span> <span> creative technologist,</span> <span> and Awwwards
                    developer jury.</span> <span> I have experience working all over:</span> <span> as a freelancer in Casablanca,</span> <span> with NGOs in India,</span> <span> startups in Stockholm,</span> <span> at Serif based in the UK,</span>
                    <span> Zero Studios based in New York,</span> <span> and at Twitter in Dublin.</span>
                </p>
                <p>
                    <span>I particularly enjoy</span> <span> web application engineering,</span> <span> object-oriented programming,</span> <span> crafting code,</span> <span>
                        and building things the world hasn't seen before.</span>
                </p>
            </div>
            <div className='index-title' ref={hw}>
                <span>h</span><span>e</span><span>l</span><span>l</span><span>o,</span><span> w</span><span>o</span><span>r</span><span>l</span><span>d</span>
            </div>
            {console.log("Credits: Diagonal arrow icons created by Those Icons - Flaticon https://www.flaticon.com/free-icons/diagonal-arrow")}
        </div>
    );

}

export default Index;