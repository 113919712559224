import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/header/header.jsx";
import Footer from "./components/footer/footer.jsx";

import Index from "./pages/index/index.jsx";
import About from "./pages/about/about.jsx";
import Lab from "./pages/lab/lab.jsx";
import Projects from "./pages/projects/projects.jsx";
import Contact from "./pages/contact/contact.jsx";

import "./App.css";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route index element={<Index />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/lab" element={<Lab />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
